import { useEffect, useState } from 'react';
import { useAccount, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../../config/auth/msal';
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';

function useGetMsalAccessToken() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [idToken, setIdToken] = useState<string | undefined>(undefined);

  const getMsalTokenSilently = async (account: AccountInfo): Promise<AuthenticationResult> => {
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });

    setAccessToken(response.accessToken);
    setIdToken(response.idToken);
    return response;
  };

  useEffect(() => {
    (async () => {
      if (account && !accessToken) {
        await getMsalTokenSilently(account);
      }
    })();
  }, [account, instance]);

  const getAccessToken = async () => {
    // If access token is already present no need to refetch it
    if (accessToken) {
      return accessToken;
    }

    if (account) {
      return (await getMsalTokenSilently(account)).accessToken;
    }
  };

  return { idToken, getAccessToken };
}

export default useGetMsalAccessToken;
