import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import { getAllCountries, getAllLanguages, getAllBoutiques } from '../helper/api';

interface MasterDataContextInterface {
  countries: any[];
  languages: any[]; 
  boutiques: any[];
  loading: boolean;
  error: Error | null;
}

const MasterDataContext = createContext<MasterDataContextInterface | null>(null);

export const useMasterData = () => {
  const context = useContext(MasterDataContext);
  if (!context) {
    throw new Error('useMasterData must be used within a MasterDataProvider');
  }
  return context;
};

export const MasterDataProvider = ({ children }: { children: ReactNode }) => {
  const [countries, setCountries] = useState<any[]>([]);
  const [languages, setLanguages] = useState<any[]>([]);
  const [boutiques, setBoutiques] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countriesData = await getAllCountries();
        setCountries(countriesData);

        const languagesData = await getAllLanguages();
        setLanguages(languagesData);

        const boutiquesData = await getAllBoutiques();
        setBoutiques(boutiquesData);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <MasterDataContext.Provider value={{ countries, languages, boutiques, loading, error }}>
      {children}
    </MasterDataContext.Provider>
  );
};
