import React, { useContext, useState, useEffect, Fragment } from "react";
import { useMsal } from "@azure/msal-react";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { APAvatar, APButtonIcon, APIcon } from "@ap/design-system";
import useGetCurrentUser from "../../services/useGetCurrentUser";
import { AppContext } from "../../../../bootstrap/App";
import { getInitials } from "../../../../helper/string";
import useGetActiveDirectoryUserThumbnail from "../../services/useGetActiveDirectoryUserThumbnail";
import LoadingSpinner from "../../../../template/components/LoadingSpinner/LoadingSpinner";
import { RoleListPublic } from "../../enums/UserRole";

const minWidth = "200px";

interface Props {
  openSettings: () => void;
}

function Account(props: Props) {
  const { openSettings } = props;

  const { instance } = useMsal();
  const { setRealmUser } = useContext(AppContext);
  const currentUser = useGetCurrentUser();
  const { getUserThumbnail } = useGetActiveDirectoryUserThumbnail();
  const [thumbnail, setThumbnail] = useState<string | undefined>();
  const [backdropOpen, setBackdropOpen] = useState(false);

  /**
   * On load fetch the thumbnail
   */
  useEffect(() => {
    (async () => {
      const data = await getUserThumbnail(currentUser.externalUserId, true);
      setThumbnail(data as string);
    })();
  }, []);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  /**
   * Sign out the user
   * - clear the local and session storage browser cache
   * - clear the Apollo client cache
   * - remove the authentication token (client side)
   * - revoke the user session (server side)
   */
  const handleClickSignOut = async () => {
    handleCloseUserMenu();
    setBackdropOpen(true);

    setRealmUser(null);

    sessionStorage.clear();
    localStorage.clear();

    instance.logoutRedirect();
  };

  const handleClickSettings = () => {
    handleCloseUserMenu();
    openSettings();
  };

  const roles =
    RoleListPublic.filter((item) => currentUser.roles.includes(item.value)).map(
      (role) => role.name
    ) ?? currentUser.roles;
  return (
    <Fragment>
      <APButtonIcon
        onClick={handleOpenUserMenu}
        sx={{ p: 0, cursor: "pointer", "&:hover": { background: "inherit" } }}
      >
        <APAvatar
          alt={currentUser.name}
          src={thumbnail}
          customAbbreviation={getInitials(currentUser.name)}
          sx={{ cursor: "pointer" }}
        />
      </APButtonIcon>
      <Menu
        sx={{ mt: "10px" }}
        id="account-menu"
        anchorEl={anchorElUser}
        keepMounted
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        transformOrigin={{ horizontal: "left", vertical: "bottom" }}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <MenuItem disabled sx={{ minWidth, opacity: "1 !important" }}>
          <Box>
            <Typography variant="body">{currentUser.name}</Typography>
            {roles &&
              roles.map((role, index) => (
                <Typography key={index} variant="caption">
                  {role}
                  <br />
                </Typography>
              ))}
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClickSettings} sx={{ minWidth }}>
          <ListItemIcon>
            <APIcon name="Settings" />
          </ListItemIcon>
          <ListItemText>Settings</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClickSignOut} sx={{ minWidth }}>
          <ListItemIcon>
            <APIcon name="Logout" />
          </ListItemIcon>
          <ListItemText>Sign out</ListItemText>
        </MenuItem>
      </Menu>
      <Backdrop
        sx={{ color: "#FFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <Stack alignItems="center" spacing={3} my={3}>
          <LoadingSpinner />
          <Typography
            sx={{ fontStyle: "italic", color: "#FFF", fontWeight: "bold" }}
          >
            Signing out...
          </Typography>
        </Stack>
      </Backdrop>
    </Fragment>
  );
}

export default Account;
