import MasterDataCountry, {
  MasterDataCountryInsertInput,
  MasterDataCountryQueryInput,
} from '../../countries/models/MasterDataCountry';
import MasterDataLanguage, {
  MasterDataLanguageInsertInput,
  MasterDataLanguageQueryInput,
} from '../../languages/models/MasterDataLanguage';
import BaseModel from '../../../models/BaseModel';
import BaseQueryInputModel from '../../../models/BaseQueryInputModel';
import MasterDataBoutique, { MasterDataBoutiqueInsertInput, MasterDataBoutiqueQueryInput } from './MasterDataBoutique';
import BaseLogModel from '../../../models/BaseLogModel';
import BaseQueryInputLogModel from '../../../models/BaseQueryInputLogModel';

export enum BoutiquesView {
  Table = 'table',
  Grid = 'grid',
}

export enum BoutiqueUserType {
  ADMIN = 'admin',
}

export interface BoutiqueUser {
  displayName: string;
  type: BoutiqueUserType;
  externalUserId: string;
  insertedManually?: boolean;
}

export interface BoutiqueCoverImage {
  unsplashId?: string;
  unsplashLink?: string;
  unsplashUserName?: string;
  unsplashUserUrl?: string;
  urlFull: string;
  urlRegular: string;
}

export default interface Boutique extends BaseModel, BaseLogModel {
  boutique?: MasterDataBoutique;
  country?: MasterDataCountry;
  coverImage?: BoutiqueCoverImage;
  language?: MasterDataLanguage;
  name: string;
  userLanguage?: MasterDataLanguage;
  users: BoutiqueUser[];
}

// MongoDB Realm GraphQL resolvers

export interface BoutiqueUserInsertInput extends BoutiqueUser {}

interface BoutiqueUserQueryInput {
  AND?: BoutiqueUserQueryInput[];
  OR?: BoutiqueUserQueryInput[];
  displayName_exists?: boolean;
  displayName_gt?: string;
  displayName_gte?: string;
  displayName_in?: string[];
  displayName_lt?: string;
  displayName_lte?: string;
  displayName_ne?: string;
  displayName_nin?: string[];
  displayName?: string;
  externalUserId_exists?: boolean;
  externalUserId_gt?: string;
  externalUserId_gte?: string;
  externalUserId_in?: string[];
  externalUserId_lt?: string;
  externalUserId_lte?: string;
  externalUserId_ne?: string;
  externalUserId_nin?: string[];
  externalUserId?: string;
  type_exists?: boolean;
  type_gt?: string;
  type_gte?: string;
  type_in?: string[];
  type_lt?: string;
  type_lte?: string;
  type_ne?: string;
  type_nin?: string[];
  type?: string;
}

interface BoutiqueCoverImageQueryInput {
  AND?: BoutiqueCoverImageQueryInput[];
  OR?: BoutiqueCoverImageQueryInput[];
  unsplashId: string;
  unsplashId_exists: boolean;
  unsplashId_gt: string;
  unsplashId_gte: string;
  unsplashId_in: string[];
  unsplashId_lt: string;
  unsplashId_lte: string;
  unsplashId_ne: string;
  unsplashId_nin: string[];
  unsplashLink: string;
  unsplashLink_exists: boolean;
  unsplashLink_gt: string;
  unsplashLink_gte: string;
  unsplashLink_in: string[];
  unsplashLink_lt: string;
  unsplashLink_lte: string;
  unsplashLink_ne: string;
  unsplashLink_nin: string[];
  unsplashUserName: string;
  unsplashUserName_exists: boolean;
  unsplashUserName_gt: string;
  unsplashUserName_gte: string;
  unsplashUserName_in: string[];
  unsplashUserName_lt: string;
  unsplashUserName_lte: string;
  unsplashUserName_ne: string;
  unsplashUserName_nin: string[];
  unsplashUserUrl: string;
  unsplashUserUrl_exists: boolean;
  unsplashUserUrl_gt: string;
  unsplashUserUrl_gte: string;
  unsplashUserUrl_in: string[];
  unsplashUserUrl_lt: string;
  unsplashUserUrl_lte: string;
  unsplashUserUrl_ne: string;
  unsplashUserUrl_nin: string[];
  urlFull: string;
  urlFull_exists: boolean;
  urlFull_gt: string;
  urlFull_gte: string;
  urlFull_in: string[];
  urlFull_lt: string;
  urlFull_lte: string;
  urlFull_ne: string;
  urlFull_nin: string[];
  urlRegular: string;
  urlRegular_exists: boolean;
  urlRegular_gt: string;
  urlRegular_gte: string;
  urlRegular_in: string[];
  urlRegular_lt: string;
  urlRegular_lte: string;
  urlRegular_ne: string;
  urlRegular_nin: string[];
}

export interface BoutiqueUpdateInput
  extends Partial<Omit<Boutique, 'boutique' | 'country' | 'language' | 'userLanguage'>> {
  boutiqueId?: {
    create?: MasterDataBoutiqueInsertInput;
    link?: string;
  };
  countryId?: {
    create?: MasterDataCountryInsertInput;
    link?: string;
  };
  language?: {
    create?: MasterDataLanguageInsertInput;
    link?: string;
  };
  userLanguage?: {
    create?: MasterDataLanguageInsertInput;
    link?: string;
  };
}

export interface BoutiqueInsertInput
  extends Omit<Boutique, 'boutique' | 'country' | 'language' | 'userLanguage' | 'users'> {
  boutiqueId?: {
    create?: MasterDataBoutiqueInsertInput;
    link?: string;
  };
  countryId?: {
    create?: MasterDataCountryInsertInput;
    link?: string;
  };
  language?: {
    create?: MasterDataLanguageInsertInput;
    link?: string;
  };
  userLanguage?: {
    create?: MasterDataLanguageInsertInput;
    link?: string;
  };
  users?: BoutiqueUserInsertInput[];
}

export interface BoutiqueQueryInput extends BaseQueryInputModel, BaseQueryInputLogModel {
  AND?: BoutiqueQueryInput[];
  OR?: BoutiqueQueryInput[];
  boutiqueId?: MasterDataBoutiqueQueryInput;
  countryId?: MasterDataCountryQueryInput;
  countryId_exists?: boolean;
  coverImage?: BoutiqueCoverImageQueryInput;
  language?: MasterDataLanguageQueryInput;
  language_exists?: boolean;
  name?: string;
  name_exists?: boolean;
  name_gt?: string;
  name_gte?: string;
  name_in?: string[];
  name_lt?: string;
  name_lte?: string;
  name_ne?: string;
  name_nin?: string[];
  userLanguage?: MasterDataLanguageQueryInput;
  userLanguage_exists?: boolean;
  users?: BoutiqueUserQueryInput[];
  users_exists?: boolean;
  users_in?: BoutiqueUserQueryInput[];
  users_nin?: BoutiqueUserQueryInput[];
}
