import { lazy } from 'react';
import { RouteObject } from 'react-router';
import { URI_USERS } from './uri';
import { UserPermission } from '../enums/UserPermission';

const Users = lazy(() => import('../pages/Users'));

const routes = (): RouteObject[] => {
  return [
    {
      path: URI_USERS,
      element: <Users />,
    },
  ];
};

export default routes;
