import { RouteObject } from 'react-router';
import { useRoutes } from 'react-router-dom';
import errorRoutes from '../../features/errors/router/routes';
import usersRoutes from '../../features/users/router/routes';
import crmRoutes from '../../features/crm/router/routes';

function AppRouter() {
  const routes: RouteObject[] = [
    ...crmRoutes(),
    ...usersRoutes(),
    ...errorRoutes,
  ];

  return useRoutes(routes);
}

export default AppRouter;
