import { JSX, useContext, useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';
import { createRoutesFromChildren, matchRoutes } from 'react-router';
import * as Sentry from '@sentry/react';
import type { BrowserOptions } from '@sentry/browser';
import { AppContext } from './App';
import useGetCurrentUser from '../features/users/services/useGetCurrentUser';

const enabled = import.meta.env.VITE_SENTRY_ENABLED === 'true';

export const sentryOptions: BrowserOptions = {
  enabled,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENV,
  release: `ap-crm-dap-backoffice@${import.meta.env.VITE_VERSION}`,
  /**
   * It controls for which URLs distributed tracing should be enabled
   * TODO: add import.meta.env.VITE_API_URL when NestJS will be ready
   *  <!> if the env variable is passed but empty, the site will crash because the Sentry headers will be passed to
   *  the IDP and rejected by the CORS policy
   */
  tracePropagationTargets: ['localhost', import.meta.env.VITE_AZURE_FUNCTION_URL],
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    /* Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }), */
  ],
  normalizeDepth: 5,
  tracesSampleRate: Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
  replaysSessionSampleRate: Number(import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
  replaysOnErrorSampleRate: Number(import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
  beforeBreadcrumb(breadcrumb) {
    if (breadcrumb.category === 'console' && breadcrumb.message && breadcrumb.message.startsWith('%c')) {
      // Exclude AppConsole console output
      return null;
    }
    return breadcrumb;
  },
};

interface Props {
  children: JSX.Element;
}

function AppSentryProvider(props: Props) {
  const { realmUser } = useContext(AppContext);
  const currentUser = useGetCurrentUser();

  useEffect(() => {
    if (realmUser && currentUser) {
      Sentry.setUser({
        id: realmUser.id,
        username: currentUser.externalUserId,
        segment: currentUser.roles.map((role) => role.toString()).join(', '),
      });
    }
  }, [realmUser?.id]);

  return <Sentry.ErrorBoundary>{props.children}</Sentry.ErrorBoundary>;
}

export default Sentry.withProfiler(AppSentryProvider);
