import { lazy } from 'react';
import { RouteObject } from 'react-router';
import { URI_CARDS } from './uri';
import { UserPermission } from '../../users/enums/UserPermission';

const CrmCards = lazy(() => import('../pages/CrmCards'));

const routes = (): RouteObject[] => {
  return [
    {
      path: URI_CARDS,
      element: <CrmCards />,
    },
  ];
};

export default routes;
