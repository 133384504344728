import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './bootstrap/App';
import { sentryOptions } from './bootstrap/AppSentryProvider';

Sentry.init(sentryOptions);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const originalError = console.error;

console.error = (...args) => {
  if (/Function components cannot be given refs/.test(args[0])) {
    return;
  }
  originalError.call(console, ...args);
};

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
