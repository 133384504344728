import { JSX, useContext, useEffect, useState } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import useGetMsalAccessToken from '../features/users/services/useGetMsalAccessToken';
import { AppContext } from './App';
import getRealmUser, { getRealmObject } from '../config/realm/getRealmUser';
import RealmUser from '../models/RealmUser';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { APProgressLoading } from '@ap/design-system';
import { setAuthToken } from '../helper/api';

const containerStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
  flexDirection: 'column',
  gap: '16px',
  height: '100%',
  padding: '32px',
};

interface Props {
  children: JSX.Element;
}

function AppRealmProvider(props: Props) {
  const isAuthenticated = useIsAuthenticated();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const { realmUser, setRealmUser, realmObject, setRealmObject } = useContext(AppContext);
  const { getAccessToken } = useGetMsalAccessToken();

  useEffect(() => {
    const fetchAccessToken = async () => {
      if (isAuthenticated) {
        const entraAccessToken = await getAccessToken();

        if (!entraAccessToken) {
          console.error('Could not retrieve access token');
          return;
        }

        setAccessToken(entraAccessToken);
        setAuthToken(entraAccessToken);
      }
    };

    fetchAccessToken();
  }, [isAuthenticated, getAccessToken]);

  useEffect(() => {
    if (!accessToken) return;

    const initializeRealm = async () => {
      try {
        const localRealmUser = (await getRealmUser(accessToken)) as RealmUser;
        if (!localRealmUser) return;

        if (accessToken !== realmUser?.accessToken) {
          setRealmUser(localRealmUser);

          const localRealmObject = getRealmObject();
          if (localRealmObject !== realmObject) {
            setRealmObject(localRealmObject);
          }
        }
      } catch (e) {
        console.error('Error initializing Realm:', e);
      }
    };

    if (!realmUser || !realmObject) {
      initializeRealm();
    }
  }, [accessToken, realmUser, realmObject, setRealmUser, setRealmObject]);
  if (!realmUser || !accessToken) {
    return (
      <Container maxWidth='sm' sx={{ flex: 1 }}>
        <Box sx={containerStyle}>
          <APProgressLoading>Initiate database connection...</APProgressLoading>
        </Box>
      </Container>
    );
  }

  return <>{props.children}</>;
}

export default AppRealmProvider;
