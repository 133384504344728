import { JSX, Fragment, Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

interface Props {
  children: JSX.Element;
}

function AppRouterProvider(props: Props) {
  useEffect(() => {
    const redirectURI = sessionStorage.getItem('redirectURI');
    sessionStorage.removeItem('redirectURI');

    if (redirectURI && redirectURI !== location.href) {
      location.replace(redirectURI);
    }
  }, []);

  return (
    <Suspense fallback={<Fragment />}>
      <BrowserRouter>{props.children}</BrowserRouter>
    </Suspense>
  );
}

export default AppRouterProvider;
