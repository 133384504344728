import { Fragment, lazy, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Stack from '@mui/material/Stack';
import useGetCurrentUser from '../../../features/users/services/useGetCurrentUser';
import useSetCurrentUserData from '../../../features/users/services/useSetCurrentUserData';
import Dialog from '../Dialog/Dialog';
import WelcomeWizardTheme from './WelcomeWizardTheme';
import WelcomeWizardRegionalFormat from './WelcomeWizardRegionalFormat';
import { APButton, APButtonLoading, APStepper } from '@ap/design-system';

// It's important here to lazy load this comment, because it uses Lottie that's quite heavy
const WelcomeWizardInfo = lazy(() => import('./WelcomeWizardInfo'));

const steps = ['Theme', 'Regional format', 'Info'];

function WelcomeWizard() {
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const { setData, loading } = useSetCurrentUserData();
  const currentUser = useGetCurrentUser();

  useEffect(() => {
      if (!currentUser.welcomeWizardCompleted) {
      setOpen(true);
    }
  }, []);

  const handlePrevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNextStep = async () => {
    if (activeStep === 2) {
      await setData([{ name: 'welcomeWizardCompleted', value: true }]);
      toast.success('Thank you. Enjoy your experience!');
      setOpen(false);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <Dialog
      open={open}
      title="Let's customize your experience"
      id='welcomeWizardDialog'
      maxWidth='md'
      actions={
        <Fragment>
          {activeStep !== 0 && (
            <APButton variant='secondary' onClick={handlePrevStep}>
              Previous
            </APButton>
          )}
          <APButtonLoading onClick={handleNextStep} loading={loading}>
            {activeStep === 2 ? 'Finish' : 'Next'}
          </APButtonLoading>
        </Fragment>
      }
    >
      <Stack spacing={6}>
        <Stack alignItems='center'>
          <APStepper nonLinear activeStep={activeStep} steps={steps} />
        </Stack>
        {activeStep === 0 && <WelcomeWizardTheme setData={setData} />}
        {activeStep === 1 && <WelcomeWizardRegionalFormat setData={setData} />}
        {activeStep === 2 && <WelcomeWizardInfo />}
      </Stack>
    </Dialog>
  );
}

export default WelcomeWizard;
